export default class InteractionConst {
    static GET_PROPERTY_VALUE = 'getvalue'
    static SET_PROPERTY_VALUE = 'setvalue'    
    static GET_VMODEL = 'getvmodel'
    static SET_VMODEL = 'setvmodel'    
    static SET_LOADED = 'setvmodel'    
    static CALL_FUNCTION = 'callproc'
    static GET_ROOT_COMPONENTS = 'getrootcomponents'
    static GET_ACTIVE_INDEX = 'getactiveindex'

    static DESTROY_ROOT_COMPONENTS = 'destroyrootcomponents'
  }
  