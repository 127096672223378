import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, vShow as _vShow, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-20689a23"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "grid"
}
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "box-panel shadow-5 flex" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "col-12 lg:col-12" }
const _hoisted_8 = {
  class: "p-overlaybadge",
  "data-pc-name": "overlaybadge",
  "data-pc-section": "root",
  pc16: ""
}
const _hoisted_9 = {
  key: 0,
  class: "p-badge p-component p-badge-circle",
  "data-pc-name": "pcbadge",
  "data-pc-extend": "badge",
  "data-pc-section": "root"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_builder = _resolveComponent("form_builder")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dock = _resolveComponent("Dock")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    (_ctx.showComponentForm && _ctx.componentInputId != -1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.showComponentFormDialog)
            ? (_openBlock(), _createBlock(_component_Dialog, {
                key: 0,
                visible: _ctx.componentInputModal,
                "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.componentInputModal) = $event)),
                maximizable: "",
                modal: "",
                styleClass: "mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6",
                contentStyleClass: "border-round-bottom border-top-1 surface-border p-0",
                onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.backToOtherComponent()))
              }, {
                header: _withCtx(() => [
                  _createElementVNode("span", { innerHTML: _ctx.componentInputHeader }, null, 8 /* PROPS */, _hoisted_2)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_form_builder, {
                    container: _ctx.container,
                    componentId: _ctx.componentInputId,
                    applicationId: _ctx.applicationId,
                    applicationVersion: _ctx.applicationVersion,
                    preview: _ctx.preview,
                    attrsInput: _ctx.componentAttributesList,
                    canDestroy: true,
                    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backToOtherComponent()))
                  }, null, 8 /* PROPS */, ["container", "componentId", "applicationId", "applicationVersion", "preview", "attrsInput"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["visible"]))
            : (_ctx.componentInputId != -1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", { innerHTML: _ctx.componentInputHeader }, null, 8 /* PROPS */, _hoisted_6),
                      _withDirectives(_createVNode(_component_Button, {
                        style: {"cursor":"pointer"},
                        icon: "pi pi-arrow-left",
                        class: "mt-3 ml-auto p-button-rounded p-button-secondary p-button-outlined",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.backToOtherComponent()))
                      }, null, 512 /* NEED_PATCH */), [
                        [_directive_tooltip, 'Volver']
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_form_builder, {
                      container: _ctx.container,
                      componentId: _ctx.componentInputId,
                      applicationId: _ctx.applicationId,
                      applicationVersion: _ctx.applicationVersion,
                      preview: _ctx.preview,
                      attrsInput: _ctx.componentAttributesList,
                      canDestroy: true,
                      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.backToOtherComponent()))
                    }, null, 8 /* PROPS */, ["container", "componentId", "applicationId", "applicationVersion", "preview", "attrsInput"])
                  ])
                ]))
              : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", null, [
      (_ctx.isKeepAlive)
        ? (_openBlock(), _createBlock(_KeepAlive, { key: 0 }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.DynamicComponent), {
              comps: _ctx.comps,
              "onUpdate:comps": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.comps) = $event)),
              container: _ctx.container,
              rootParentId: _ctx.componentId,
              formKey: _ctx.formKey,
              lookUpComps: _ctx.lookUpComps
            }, null, 40 /* PROPS, NEED_HYDRATION */, ["comps", "container", "rootParentId", "formKey", "lookUpComps"]))
          ], 1024 /* DYNAMIC_SLOTS */))
        : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.DynamicComponent), {
            key: 1,
            comps: _ctx.comps,
            "onUpdate:comps": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.comps) = $event)),
            container: _ctx.container,
            rootParentId: _ctx.componentId,
            formKey: _ctx.formKey,
            lookUpComps: _ctx.lookUpComps
          }, null, 40 /* PROPS, NEED_HYDRATION */, ["comps", "container", "rootParentId", "formKey", "lookUpComps"]))
    ], 512 /* NEED_PATCH */), [
      [_vShow, (!_ctx.showComponentForm || _ctx.showComponentFormDialog) && _ctx.isLoaded]
    ]),
    (_ctx.pendingOperations.length > 0)
      ? (_openBlock(), _createBlock(_component_Dock, {
          key: 1,
          model: _ctx.itemsDock,
          position: 'right'
        }, {
          item: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createVNode(_component_Button, {
                icon: item.icon,
                severity: item.severity,
                onClick: ($event: any) => (_ctx.onDockItemClick($event, item)),
                style: {"opacity":"0.6"}
              }, null, 8 /* PROPS */, ["icon", "severity", "onClick"]), [
                [
                  _directive_tooltip,
                  item.label,
                  void 0,
                  { left: true }
                ]
              ]),
              (item.badge)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(item.badge), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model"]))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */)), [
    [_vShow, _ctx.isLoadedKeepAlive]
  ])
}