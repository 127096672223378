import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';
import { RedirectControl } from '../controls/redirectControl';
import { Router } from 'vue-router';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import { CustomMenu } from '../../../../../../../common/domain/modelos/menu/CustomMenu';
import { searchMenuTree } from '../../../../../menu/infrastructure/HelperMenu';
import { DataflowEngine } from 'rete-engine';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';


import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import { ExpresionEngine } from '../../../../../expression/infrastructure/helper/expressionEngine';
import RedirectNodeConst from '../constants/RedirectNodeConst';
import { LocalService } from '../../../../../../../common/infrastructure/servicios';
import { Dictionary } from '../../../../../expression/domain/dictionary';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import { IapWorkFlowActivityControl } from '../../../../domain/service/iapWorkFlowActivityControl';

export class RedirectNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket, message: ClassicPreset.Socket  },
  { ejecutar: ClassicPreset.Socket },
  { value: RedirectControl }
> {
  height = 490;
  width = 380;

  private showExpression: any;
  private getNodeInternalData : any;
  private dataflow: DataflowEngine<Schemes>
  private value: Number;
  private selectedValue: Number;
  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private rdControlId: '';
  private router:Router;
  private componentData: IapComponent[]
  private store :any;
  private parameters: Dictionary<String,Dictionary<String,String>>[];
  private rootComponentId: number;
  private currentComponentId: number;

  private formKey:String;
  private currentElementKey:string;

  private variableRedirectType: Number;
  private  variableParamsRedirectUrl: String;
  private variableParamsRedirectUrlTarget: String;
  private variableParamsRedirectUrlParams: String;


  private activity: IapWorkFlowActivity | undefined;

  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,public formKeyInput: String,public currentElementKeyInput:string,public rootComponentInputId: number,public currentComponentInputId: number,
    public variableRedirectTypeInput: Number,
    public variableParamsRedirectUrlInput: String,
    public variableParamsRedirectUrlTargetInput: String,
    public variableParamsRedirectUrlParamsInput: String,
    selectedValue: Number, parametersInput: Dictionary<String,Dictionary<String,String>>[], updateNode: any = undefined, getNodeInternalData: any = undefined, showExpressionFunction: any = undefined,router:Router,storeInput:any,componentDataInput: IapComponent[], itemActivity: IapWorkFlowActivity | undefined, dataflow: DataflowEngine<Schemes>) {
    super("Redirect");
    this.selectedValue = selectedValue;
    this.area = area;
    this.updateNode = updateNode;
    this.value = selectedValue;
    this.parameters = parametersInput;
    this.dataflow = dataflow;
    this.router=router;
    this.componentData = componentDataInput;
    this.store = storeInput;
    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;
    this.currentComponentId = currentComponentInputId;
    this.activity = itemActivity;
    this.rootComponentId = rootComponentInputId;    
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;

    this.variableRedirectType= variableRedirectTypeInput;
    this.variableParamsRedirectUrl =variableParamsRedirectUrlInput;
    this.variableParamsRedirectUrlTarget = variableParamsRedirectUrlTargetInput;
    this.variableParamsRedirectUrlParams = variableParamsRedirectUrlParamsInput;


    const dsControl = new RedirectControl(variableRedirectTypeInput,variableParamsRedirectUrlInput,variableParamsRedirectUrlTargetInput,variableParamsRedirectUrlParamsInput,selectedValue,parametersInput, this.updateData,this.getNode,this.showExp);

    this.rdControlId = (dsControl as any).id;

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      dsControl

      //new ClassicPreset.InputControl("text", { initial })
    );

    this.addInput("message", new ClassicPreset.Input(socket, "ParameterInput"));
    this.addOutput("ejecutar", new ClassicPreset.Output(socket, "Ejecutar"));


    //area.update("control",dsControl.id)
    //this.addOutput("value", new ClassicPreset.Output(socket, "Valor"));





  }

  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }

  updateData = (evt: any) => {
    
    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;

    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      
      this.updateNode(this.id, evt.key, (evt.key == RedirectNodeConst.VAR_PAR ? JSON.stringify(evt.data) : evt.data), (evt?.operation ?? OperationDataTypeConst.UPDATE))
    }


  }


  resolveExpressions = () =>{
    
    const currentComp = this.componentData.find(x => x.id == this.currentComponentId)

    const wcf = this.activity?.iapWorkFlowActivityControls.find(x => x.name == RedirectNodeConst.VAR_PAR);

    if (currentComp && wcf && this.parameters.length>0) 
    {
      this.parameters.forEach(param => {
        const key = '#' + wcf.id.toString() + '#parameterId=' + param.key;        
        const exps = currentComp.expressions?.filter(x => x.idObjeto == CatalogObjectTypeConst.WFAC && x.idTypeExpression == CatalogExpConst.EXP_SET && x.objetoId.endsWith(key) && x.iapExpressionDetails?.length > 0);
        if (exps?.length > 0) {
          exps?.every(exp => {
            if (exp.iapExpressionDetails?.length > 0) {
              const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
              const data = HelperUtils.jsonParse(localData,[])
              let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
              //resu = resu?.toString();
              if (resu) {
                if (Object.keys(resu).length == 0) {
                  resu = resu?.toString();
                }
              }


              param.value = resu;


            }
          })

        }
      })

    }
    else{

       this.activity?.iapWorkFlowActivityControls.forEach((wcf: IapWorkFlowActivityControl)=>{

        const key = '#' + wcf.id.toString() + '#parameterName='+wcf.name;        
        const exps = currentComp?.expressions?.filter(x => x.idObjeto == CatalogObjectTypeConst.WFAC && x.idTypeExpression == CatalogExpConst.EXP_SET && x.objetoId.endsWith(key) && x.iapExpressionDetails?.length > 0);
        if (exps && exps?.length > 0) {
          exps?.every(exp => {
            if (exp.iapExpressionDetails?.length > 0) {
              const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
              const data = HelperUtils.jsonParse(localData,[])
              let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
              if (resu) {
                if (Object.keys(resu).length == 0) {
                  resu = resu?.toString();
                }
              }

              switch(wcf.name) {
                case RedirectNodeConst.VAR_PARURL:
                  this.variableParamsRedirectUrl = resu
                  break;
                case RedirectNodeConst.VAR_PARURLTARGET:
                  this.variableParamsRedirectUrlTarget = resu;
                  break;
                case RedirectNodeConst.VAR_PARURLPARAMS:
                    this.variableParamsRedirectUrlParams = resu;
                    break;
                default:
              }
  
  
  
  
            }
       
      })
    }
    })
  

    }
    
    
  }


  async execute(_: never, forward: (output: "ejecutar") => void) {

    
    const inputs = (await this.dataflow.fetchInputs(this.id)) as {
      message: string[];
    };

     //this.parameters = this.variableMsg ?? '';

    const msg = inputs?.message?.find(x => x !== undefined);
    if ((msg && Array.isArray(msg) && msg?.length > 0) ) {

      var keys = Object.keys(msg[0])
      keys.forEach(key => {
     
        this.parameters.forEach(data =>{
          //@ts-ignore:disable-next-line
          data.value.value = data.value.value.replaceAll('#' + key + '#', msg[0][key])
        })
        
      });

      this.parameters.forEach(data =>{
        //@ts-ignore:disable-next-line
        data.value.value = data.value.value.replaceAll('#ParameterInput#', JSON.stringify(msg))
      })
    
      
    }


    const input =inputs?.message?.find(x => x !== undefined);

    if(input && this.variableRedirectType == 0 && (!this.parameters || this.parameters.length === 0)){

      var keys = Object.keys(input)
      keys.forEach(key => {
        //@ts-ignore:disable-next-line
        this.variableParamsRedirectUrl=this.variableParamsRedirectUrl.replaceAll('#' + key + '#', input[key])
        //@ts-ignore:disable-next-line
        this.variableParamsRedirectUrlInput=this.variableParamsRedirectUrlInput.replaceAll('#' + key + '#', input[key])
        //@ts-ignore:disable-next-line
        this.variableParamsRedirectUrlParams=this.variableParamsRedirectUrlParams.replaceAll('#' + key + '#', input[key])
        //@ts-ignore:disable-next-line
        this.variableParamsRedirectUrlParamsInput=this.variableParamsRedirectUrlParamsInput.replaceAll('#' + key + '#',input[key])
     
       
      });

    }


    /*
    else if (msg) {
      if (typeof(msg) === 'object'){
        this.parameters = JSON.stringify(msg ?? '{}') as any
      }
      else{
        this.parameters = msg as any
      }
      
    }
    */

 

    this.resolveExpressions();

    
    // redireccion de window.open
    if (this.variableRedirectType == 0){
        window.open(this.variableParamsRedirectUrl as any,this.variableParamsRedirectUrlTarget as any,this.variableParamsRedirectUrlParams as any);
    }
    else{
      const cusMenus = this.store.getters.getApplicationMenus as CustomMenu[];
    
      if (cusMenus)
      {
        const menu = searchMenuTree(cusMenus,this.selectedValue) as CustomMenu
        if (menu && menu?.route)
        {
          const _route = (menu?.route ?? '/')
          let _params:any={}
          this.parameters.forEach(data =>{
            try{
              _params[(data.value.key ?? '').toString()] = JSON.parse(data.value.value as string)
            }catch{
              _params[(data.value.key ?? '').toString()] = data.value.value
            }
            
            
          })
          
          if (this.parameters.length > 0 && _params && Object.keys(_params ?? {}).length> 0 ){
            
            const _route = this.router.getRoutes().find(x=> x.name == menu.id.toString())
            
            if (_route)
            {
              _params['componentId']= menu.componentId
              _route.meta.tag = JSON.stringify(_params)
            }
            
            //this.router.push({ name: menu.id.toString(), params:{queryOperation: JSON.stringify(_params)}});
          }
          
          //else{
            this.router.push({ path: _route as any});
          //}
          
          
        }
      }
    }


    

    forward("ejecutar");

  }


  data(): { value: Number } {
    return {
      value: this.selectedValue ?? -1
    };
  }
}