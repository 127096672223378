<template>
    <Login v-if="$route.path === '/login'" />
    <!--<Error v-else-if="$route.path === '/error'" />-->
    <!--<Access v-else-if="$route.path === '/access'" />
    <NotFound v-else-if="$route.path === '/notfound'" />-->
    <!--<AppPrestige  :theme="theme" :layout="layout"  v-else/>-->
    <!--<AppUltima v-else  />      -->

    <div v-else>
       
        <component  :is="selectedTemplate" />
        <UbmChat :container=container></UbmChat>
        <Vidle @idle="onidle" @remind="onremind" :loop="false" :reminders="[60]" :wait="5" :duration="60000" />
        <FormBuilder v-if="componentParentId > 0" :container="container" :applicationId="userAppId"
              :applicationVersion="userAppVersion" :componentId="componentParentId" :preview="false" :canDestroy="true"></FormBuilder>
    </div>
    <Cargando ref="cargando"></Cargando>
    

    <!--<AppPrestige v-if="isActiveTemplate(templatePrestige)"   />-->
    <!--<AppUltima v-else  />      -->
</template>
<script lang="ts">

import { computed, defineComponent, onMounted, ref, watch, nextTick, watchEffect, onUnmounted } from 'vue';
import { useStore } from 'vuex'
// import Error from './paginas/app/Error.vue';
// import Access from './paginas/app/Access.vue';
import Login from './infrastructure/paginas/login/Login.vue'
import { CookieService, LocalService, MessageService } from '@ilinium/shared/src/common/infrastructure/servicios'
import { useRoute, useRouter } from 'vue-router'
import Environment from '@ilinium/shared/src/common/infrastructure/funciones/environment'
import { defineAsyncComponent } from 'vue'
import TemplateTypeConst from '@ilinium/shared/src/common/domain/constantes/TemplateTypeConst'
import UbmChat from '@ilinium/shared/src/entidades/builder/chat/infrastructure/UbmChat.vue'
import { autoAuthRequest } from '@ilinium/shared/src/entidades/builderMaster/auth/domain/autoAuthRequest'
import { Actions } from '@ilinium/shared/src/common/infrastructure/almacen/enums/StoreEnums';
import Cargando from '@ilinium/shared/src/common/infrastructure/componentes/base/common/cargando/Cargando.vue'
import { container } from '../src/infrastructure/container'
import HelperLogin from '@ilinium/shared/src/entidades/builderMaster/auth/infrastructure/component/HelperLogin';
import EventBusCustom from '@ilinium/shared/src/common/infrastructure/event-bus-custom';
import EventConst from '@ilinium/shared/src/common/domain/constantes/EventConst';
import { AppLayoutConfig } from '../../shared/src/entidades/builder/template/infrastructure/prime/AppLayoutConfig'
import BlockViewer from './infrastructure/templates/prime/apollo/components/BlockViewer.vue';
import { app } from './main';
import { getCatalogsFromService } from '@ilinium/shared/src/entidades/builder/catalog/infrastructure/helper/helperCatalog';
import Vidle from '@ilinium/shared/src/common/infrastructure/componentes/base/common/idle/Vidle.vue';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import { db } from '@ilinium/shared/src/common/domain/db';
import DexieConst from '../../shared/src/common/domain/constantes/Dexie/DexieConst';
import  FormBuilder  from '@ilinium/shared/src/entidades/builder/form/infrastructure/FormBuilder.vue'
import CatalogConfigurationConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogConfigurationConst';
import HelperApplication from '@ilinium/shared/src/entidades/builder/application/infrastructure/functions/helperApplication';


export default defineComponent({
    name: 'app_wrapper',
    components: {
        Login,
        UbmChat,
        Cargando,
        Vidle,
        FormBuilder
    },
    props: {
       
    },
    setup() {
        

        //const memoryPerformance = timer(0, 1000)
      // @ts-ignore
      //.pipe(map(() => store.getters.isUserAuthenticated ? window.performance.memory : null));

        const store = useStore()
        const router = useRouter()
        let isLoading = ref<boolean>(false);
        const cargando = ref();
        const { getAppKeyValue } = HelperApplication(undefined)

        const componentParentId = computed(() => { return store?.getters.getAppLayoutComponentId ?? -1 });
        const whoAmIProfileDone = computed(() => { return store?.getters.getCurrentProfile ?? null });
        const loginDoneWithoutWhoAmIProfile = computed(() => { return store?.getters.getAppVersion ?? null });
        const userAppId = computed(() => { return store?.getters.getCurrentUser.applicationId ?? null });
        const userAppVersion = computed(() => { return store?.getters.getCurrentUser.applicationVersion ?? null });

        if (!store.getters.isUserAuthenticated) {
            var value = window.location.pathname.length > 1 ? window.location.pathname.substr(1) : 'login';
            var query = window.location.search ?? '';
            const searchParams = new URLSearchParams(query);
            
            if (!value.includes('login') && window.location.href.includes('/rfps/?query=')) {
                CookieService.setCookie(CookieService.COOKIE_REDIRECT_URL, ('/' + value + query), 60 * 5); // 5 minutos
            } 
            else if (searchParams.get('session') && searchParams.get('applicationId') && searchParams.get('applicationVersion')) {
                const session = searchParams.get('session');
                const applicationId = searchParams.get('applicationId');
                const applicationVersion = searchParams.get('applicationVersion');

                if (applicationId != null && applicationVersion != null && (+applicationId != Environment.APPID || +applicationVersion != Environment.APPVERSION)) {
                    MessageService.showMessage(MessageType.Error, 'Error de validación', 'Las aplicaciones no coinciden.');
                    return null;
                }

                if (session && applicationId && applicationVersion) {
                    externalLogin(session, +applicationId, +applicationVersion);
                }
            }
            else {
                CookieService.destroy(CookieService.COOKIE_REDIRECT_URL);
            }
            if (value.includes('resetPass')) {
                router.push({ name: 'login', query: { isResetPass: 'true', query: searchParams.get('query') } });
            }
            else {
                router.push({ name: 'login' });
            }

            //const route = useRoute()
            
        }
        else {
            //this.addTab();
            //initializeChat();
        }

        async function navigateLastPath() {
            const myHistory = await db.browsing.where("userId").equals(store.getters.getCurrentUser.id)
                            .and((record: any) => record.applicationId === Environment.APPID && Environment.APPVERSION === 1)
                            .sortBy("date").then(records => records.reverse());
            if (myHistory.length > 0){
                router.push(myHistory[0].path)
            }
            
        }
        async function addPathToBrowsing(path: string) {
            try {
                if (store.getters.isUserAuthenticated) {
                    const count = await db.browsing.where("userId").equals(store.getters.getCurrentUser.id)
                            .and((record: any) => record.applicationId === Environment.APPID && Environment.APPVERSION === 1)
                            .count();
                    if (count >= DexieConst.MAX_BROWSING) {
                        const recordsToDelete = await db.browsing.where("userId").equals(store.getters.getCurrentUser.id)
                            .and((record: any) => record.applicationId === Environment.APPID && Environment.APPVERSION === 1)
                            .sortBy("date");//.reverse()

                        const limitedRecords = recordsToDelete.slice(0, count - DexieConst.MAX_BROWSING - 1);
                        await db.browsing.bulkDelete(limitedRecords.map(record => record.id));
                    }
                    const count2 = await db.browsing.count();
                    // Add the new friend!
                    const id = await db.browsing.add({
                        path: path,
                        date: new Date(),
                        userId: store.getters.getCurrentUser.id,
                        applicationId: Environment.APPID,
                        applicationVersion: Environment.APPVERSION
                    });
                }
            } catch (error) {
                
            }
        }

        function externalLogin(sessionId: string, applicationId: number, applicationVersion: number) {
            const request = ref<autoAuthRequest>({
                    brokerId: '0',
                    sessionId: sessionId,
                    profileId: 0,
                    applicationId: applicationId,
                    applicationVersion: applicationVersion,
                    external: true,
                });

                isLoading.value = true;
                //cargando.value.mostrar();
                store
                    .dispatch(Actions.AUTOAUTH, [request, container])
                    .then(() => {
                        getCatalogsFromService(container, applicationId, applicationVersion).then(response => {})
                        //visibleRight.value = false;
                        //store.dispatch(Actions.CHANGEPROFILE, profileIdentifier)
                        HelperLogin.getApplicationMenus(true, router, Environment.APPID, Environment.APPVERSION, container, '', true).then(() => {
                            });        
                    })
                    .finally(() => {
                        isLoading.value = false;
                        (cargando.value).ocultar();
                        
                    });
        }

        const selectedTemplate = computed(() => {
            var appLayoutCfg = store.getters.getAppLayoutConfig as AppLayoutConfig;

            if (appLayoutCfg.template != undefined) {
                switch (appLayoutCfg.template) {
                    case TemplateTypeConst.PRIME_PRESTIGE:
                        //require('./infrastructure/templates/prime/prestige/App.scss');
                        import('./infrastructure/templates/prime/prestige/BlockViewer.vue').then((BlockViewer)=>{
                            //require('./infrastructure/templates/prime/prestige/assets/styles.scss');
                            /*
                            require('primevue/resources/primevue.min.css');
                            require('primeflex/primeflex.css');
                            require('primeicons/primeicons.css');
                            require('prismjs/themes/prism-coy.css');
                            */
                            app.component('BlockViewer', BlockViewer);
                        
                        });
                        return defineAsyncComponent(() =>import('./infrastructure/templates/prime/prestige/AppPrestige.vue'));                    
                    case TemplateTypeConst.PRIME_PRESTIGEIS:
                        //require('./infrastructure/templates/prime/prestige/App.scss');
                        return  defineAsyncComponent(() =>
                                import('./infrastructure/templates/prime/prestige_intrasoft/AppPrestige.vue'));


                    case TemplateTypeConst.PRIME_PRESTIGE_INTRASOFT:
                        //require('./infrastructure/templates/prime/prestige/App.scss');
                        return defineAsyncComponent(() =>
                                import('./infrastructure/templates/prime/prestige_intrasoft/AppPrestige.vue'));


                    case TemplateTypeConst.PRIME_ULTIMA:
                        import('./infrastructure/templates/prime/ultima/components/BlockViewer.vue').then((BlockViewer)=>{
                            require('./infrastructure/templates/prime/ultima/assets/styles.scss');
                            app.component('BlockViewer', BlockViewer);
                        });
                        return defineAsyncComponent(() =>
                                import('./infrastructure/templates/prime/ultima/AppUltima.vue'));
                    case TemplateTypeConst.PRIME_APOLLO:
                        require('./infrastructure/templates/prime/apollo/assets/styles.scss');
                        app.component('BlockViewer', BlockViewer);
                        return defineAsyncComponent(() =>
                                import('./infrastructure/templates/prime/apollo/AppApollo.vue'));
                    default:
                    return defineAsyncComponent(() =>
                                import('./infrastructure/templates/prime/prestige_intrasoft/AppPrestige.vue'));
                }
            }

        })


        const onidle = () => {
            var doCallbackOk = () => {
                HelperCommon.salir(container ,router);
            }
            MessageService.showMessage(MessageType.Aviso, '', 'Se ha superado el tiempo de inactividad y se va a cerrar la sesión automáticamente.', false, false, false, '', doCallbackOk, null, 10000);
        }
        
        const onremind = (time) => {
            MessageService.showToast(MessageType.Aviso, '', 'Faltan ' + time + ' segundos para cerrar la sesión por inactividad.');
        }



        router.beforeEach((to, from, next) => {
            // establecemos el titulo de la página
            document.title=getAppKeyValue(CatalogConfigurationConst.TITLE,Environment.ENVIRONMENT??'',store.getters.getAppConfig) ?? 'Nueva Aplicación';
            
            addPathToBrowsing(to.path);
            if (to.path !== '/login' && !store.getters.isUserAuthenticated){
                HelperCommon.salir(container ,router);
                //next({ name: 'Login' })
            } 
            else{
                if (store.getters.isUserAuthenticated && !to.name){

                    navigateLastPath();         
                }
                
                next();
            }
            
        })
        
      
        const doComponentEvent = (show:boolean) =>{
            if (show) {
                    if (cargando.value) {
                        cargando.value.mostrar()
                    }
                } else {
                    if (cargando.value) {
                        cargando.value.ocultar()
                    }
                }
        }

        onMounted(() => {
            EventBusCustom.clearAll();
            EventBusCustom.on(EventConst.LOADING,doComponentEvent)
        })

        onUnmounted (()=>{
            EventBusCustom.off(EventConst.LOADING,doComponentEvent)
        })




        return {
            selectedTemplate,
            cargando,
            container,
            onidle,
            onremind,
            componentParentId,
            whoAmIProfileDone,
            loginDoneWithoutWhoAmIProfile,
            userAppId,
            userAppVersion,
            //memoryPerformance
        };
    },
});
</script>
<style>
.react-draggable {
  margin-bottom: 30px;
}

.customrequired:before {
  content:"* " !important;
  color: red !important;
}
.p-invalid{
  border-color: #e24c4c;
  color: #e24c4c;
}

.p-disabled{
    cursor: not-allowed !important;
}

.p-readonly {    
    cursor: not-allowed;
}
.p-readonly > input {    
    cursor: not-allowed;
    pointer-events: none;
}

.p-readonly > span {    
    cursor: not-allowed;
    pointer-events: none;
}

.p-readonly > span > input {    
    cursor: not-allowed;
    pointer-events: none;
}

.p-readonly > div.p-editor-content > div.ql-editor  {    
    cursor: not-allowed;
    pointer-events: none;
}

.p-menuitem-separator {
    border-top: 1px solid #e5e7eb;
    margin: 0.25rem 0;
}

.p-scrolltop {
  z-index:99999 !important
}

</style>