/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import MessageService, { MessageType } from '../servicios/MessageService'
import { shallowRef, triggerRef } from 'vue';
import CatalogDataTypeConst from '../../../entidades/builder/catalog/domain/const/CatalogDataTypeConst';
const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

export const newGuid = function() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const RemoveObjectById = function(id: any, arr: any[]) {
  const indexOfObject = arr.findIndex((obj: { id: any }) => {
    return obj.id === id
  })

  if (indexOfObject !== -1) {
    arr.splice(indexOfObject, 1)
  }
  return arr
}

const isIsoDateString = (value: string | any): boolean => {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

const handleDates = (body: Record<string, Date> | null | undefined) => {
  if (body === null || body === undefined || typeof body !== 'object') return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (isIsoDateString(value)) body[key] = new Date(value)
    else if (typeof value === 'object') handleDates(value as any)
  }
}

const handleDatesApollo = (body: any | null | undefined) => {
  if (body === null || body === undefined || typeof body !== 'object') return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (isIsoDateString(value)) body[key] = new Date(value)
    else if (typeof value === 'object') handleDates(value as any)
  }
}


const parseObjectJson = (body: any | null | undefined) => {
  
  if (body === null || body === undefined || typeof body !== 'object') return body

  for (const key of Object.keys(body)) {
  
    const value = body[key]
    if (value && typeof value === 'string') body[key] = JSON.parse(body[key])
    else if (typeof value === 'object') parseObjectJson(value as any)
  }
}

const isNumeric = (str: string | number | null | undefined) => {
  if (str === '' || str == null || str === undefined) return false
  if (typeof str !== 'string' || !str) return false

  try {
    // str = str.replaceAll(' ', '')

    // return !isNaN(str) && !isNaN(parseFloat(str))
  } catch {
    return false
  }
}

const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) =>
  array.reduce((map, value, index, array) => {
    const key = predicate(value, index, array)
    map.get(key)?.push(value) ?? map.set(key, [value])
    return map
  }, new Map<Q, T[]>())

const lockEdit = (canEdit: any, data: Record<string, boolean>) => {
  if (!canEdit && data) {
    for (const propt in data) {
      data[propt] = true
    }
  }
}

const addYears = (dt: { setFullYear: (arg0: any) => string | number | Date, getFullYear: () => any }, n: any) => {
  // return new Date(dt.setFullYear(dt.getFullYear() + n))
}

const copyToClipBoard = (texto: string) => {
  // navigator.clipboard.writeText(texto)
  MessageService.showToast(MessageType.Correcto, '', 'Se han copiado los registros al portapapeles.')
}



const utf8_to_b64 = ( str:string ) => {
  return btoa(unescape(encodeURIComponent( (str ?? '') )));
}

const  b64_to_utf8 = ( str:string ) => {
  return decodeURIComponent(escape(window.atob( str )));
}


const getDeepKeys = (obj:any) => {
  let keys = Array<String>();
  for(let key in obj) {
      keys.push(key);
      if(typeof obj[key] === "object") {
          let subkeys = getDeepKeys(obj[key]);
          keys = keys.concat(subkeys.map(function(subkey:any) {
              return key + "." + subkey;
          }));
      }
  }
  return keys;
}


const getDeepObjectByKey = (obj:any, keyInput:string,keyValue:string) : object | null => {

  for(let key in obj) {
      //keys.push(key);
      if (key === keyInput && obj[key] !== "object" && obj[key] === keyValue)
      {
        return obj;
        //return obj[key]
      }

      if(typeof (obj[key] ?? '')  === "object") {
          return getDeepObjectByKey(obj[key],keyInput,keyValue);
          
      }
  }
  return null;
}

const setDeepObjectByKey = (obj:any, keyInput:string,keyValue:string) : object | null => {

  for(let key in obj) {
      //keys.push(key);
      if (key === keyInput && obj[key] !== "object")
      {
        obj[key] = keyValue
        return obj;
        //return obj[key]
      }

      if(typeof (obj[key] ?? '')  === "object") {
          return getDeepObjectByKey(obj[key],keyInput,keyValue);
          
      }
  }
  return null;
}


const getDeepvalueObjectByKey = (obj:any, keyInput:string) : object | null => {

  for(let key in obj) {
      //keys.push(key);
      if (key === keyInput && obj[key] !== "object")
      {
        return obj;
        //return obj[key]
      }

      if(typeof (obj[key] ?? '')  === "object") {
          const _resu = getDeepvalueObjectByKey(obj[key],keyInput);
          if (_resu){
            return _resu
          }
          
      }          
  }
  return null;
}

const isUUID = ( uuid:string ) :boolean  => {
  let s = "" + uuid;

  const resu = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
  if (resu === null) {
    return false;
  }
  return true;
}

const containUUID = ( uuid:string ) :boolean  => {
  let s = "" + uuid;

  const resu = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}');
  if (resu === null) {
    return false;
  }
  return true;
}


const PATTERN = /[\^|\[|\.]([$|\w]+)/gu

function propValue(o:any, s:string) {
  const _s = 'myObject.' + s;  
  const names = new Array<string>()
    for(let [, name] of [..._s.matchAll(PATTERN)]) 
        names.push(name)
    
    return names.reduce((p, propName) => {      
        if(!p.hasOwnProperty(propName))
        {
          throw 'invalid property name:' + propName
        } 
            
        return p[propName]
    }, o)
}

function hasPropValue(o:any, s:string) {
  const _s = 'myObject.' + s;  
  const names = new Array<string>()
    for(let [, name] of [..._s.matchAll(PATTERN)]) 
        names.push(name)
    
    return names.reduce((p, propName) => {
        if(!p.hasOwnProperty(propName)) 
            false
        return true
    }, o)
}

/**
 * Add a new item to a shallowRef array and update UI/trigger watches
 * @param {ShallowRef} shallowRefArr - ShallowRef array to add items to and trigger update
 * @param {(any|any[])} newItems - Item or array of items to add to array 
 */
function addItemToShallowRefArray(shallowRefArr:any, newItems:any) {
  let newItemsToUse = Array.isArray(newItems) ? newItems : [newItems];
  shallowRefArr.value = [...shallowRefArr.value, ...newItemsToUse]
}


function createShallowRef(value:any,options:any=undefined){
  const r = shallowRef(value);

  const get = () => r.value;
  //@ts-ignore:disable-next-line
  const set = (v) => {
    r.value = typeof v === 'function' ? v(r.value) : v;
    if(options?.equals === false){
      triggerRef(v)
    }

  }

}
/*
*
* Función para reemplazar en los strings usando expresiones regulares
*/

function replaceAll(str:string|null, find:string, replace:string, ignoreCase:boolean=false) {
  return str?.replace(new RegExp(find, (ignoreCase ? 'gi':'g')), replace);
}


const formatSize = (bytes:any) => {
  const k = 1024;
  const dm = 3;
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb']

  if (bytes === 0) {
      return `0 ${sizes[0]}`;
  }

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${formattedSize} ${sizes[i]}`;
};

const parseAllObjects = (data:any, propertyName:string='children') =>{

  data.forEach( (item:any) =>{
      if (item[propertyName])
      {
          if (typeof item[propertyName] == 'string' ){
              item[propertyName] = JSON.parse(item[propertyName])
              parseAllObjects(item[propertyName])
          }
          else{
            parseAllObjects(item[propertyName])
          }
      }
  })

}

const booleanify = (value: string): boolean => {
  const truthy: string[] = ["true", "True", "1"];

  return truthy.includes(value);
};
  

const jsonParse = (jsonString:string | null | undefined, defaultReturn:any=null) =>{
  try { 
    if (jsonString){
      return JSON.parse(jsonString); 
    }
    
} catch (error) { 
    return defaultReturn;
}
return defaultReturn;
}

const resolveAttrValue=(idDataType:string | null,value:any):any=>{
  if(idDataType && idDataType==CatalogDataTypeConst.BOOL && value){
    return  booleanify(value?.toString() ?? "false");
  }
  return value;
}

var binArrayToJson = function(binArray:any) {
  var str = "";
  for (var i = 0; i < binArray.length; i++) {
      str += String.fromCharCode(parseInt(binArray[i]));
  }     return JSON.parse(str)
}

export default {booleanify, resolveAttrValue,isUUID,containUUID,newGuid, RemoveObjectById, handleDates,handleDatesApollo, isNumeric, groupByToMap, lockEdit, addYears, copyToClipBoard,utf8_to_b64,b64_to_utf8, getDeepKeys,getDeepObjectByKey,propValue,hasPropValue, parseObjectJson, isIsoDateString,addItemToShallowRefArray, replaceAll,formatSize,getDeepvalueObjectByKey,setDeepObjectByKey,parseAllObjects,createShallowRef,jsonParse,binArrayToJson }
