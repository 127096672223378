import { App, createApp, reactive } from 'vue'

import PrimeVue from 'primevue/config'
import AutoComplete from 'primevue/autocomplete'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
import Breadcrumb from 'primevue/breadcrumb'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import Carousel from 'primevue/carousel'
import Chart from 'primevue/chart'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Chips from 'primevue/chips'
import ColorPicker from 'primevue/colorpicker'
import Column from 'primevue/column'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmationService from 'primevue/confirmationservice'
import ContextMenu from 'primevue/contextmenu'
import DataTable from 'primevue/datatable'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import Fieldset from 'primevue/fieldset'
import FileUpload from 'primevue/fileupload'
//@ts-ignore:disable-next-line
//import FullCalendar from 'primevue/fullcalendar'

import Galleria from 'primevue/galleria'
import Image from 'primevue/image'
import InlineMessage from 'primevue/inlinemessage'
import Inplace from 'primevue/inplace'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import Knob from 'primevue/knob'
import Listbox from 'primevue/listbox'
import MegaMenu from 'primevue/megamenu'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
import OrderList from 'primevue/orderlist'
import OrganizationChart from 'primevue/organizationchart'
import OverlayPanel from 'primevue/overlaypanel'
import Paginator from 'primevue/paginator'
import Panel from 'primevue/panel'
import PanelMenu from 'primevue/panelmenu'
import Password from 'primevue/password'
import PickList from 'primevue/picklist'
import ProgressBar from 'primevue/progressbar'
import Rating from 'primevue/rating'
import RadioButton from 'primevue/radiobutton'
import Ripple from 'primevue/ripple'
import SelectButton from 'primevue/selectbutton'
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop'
import Slider from 'primevue/slider'
import Sidebar from 'primevue/sidebar'
import Skeleton from 'primevue/skeleton'
import SpeedDial from 'primevue/speeddial'
import SplitButton from 'primevue/splitbutton'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Steps from 'primevue/steps'
import StyleClass from 'primevue/styleclass'
import TabMenu from 'primevue/tabmenu'
import Tag from 'primevue/tag'
import TieredMenu from 'primevue/tieredmenu'
import Textarea from 'primevue/textarea'
import Timeline from 'primevue/timeline'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Toolbar from 'primevue/toolbar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Tooltip from 'primevue/tooltip'
import ToggleButton from 'primevue/togglebutton'
import Tree from 'primevue/tree'
//import Tree from '../../../entidades/builder/workflow/infrastructure/component/rete/customization/CustomTree.vue'
import TreeSelect from 'primevue/treeselect'
import TreeTable from 'primevue/treetable'
import TriStateCheckBox from 'primevue/tristatecheckbox'
//import TriStateCheckBox from '../../../entidades/builder/workflow/infrastructure/component/rete/customization/CustomTriStateCheckBox.vue'
import ProgressSpinner from 'primevue/progressspinner'
import CascadeSelect from 'primevue/cascadeselect';
// import Editor from 'primevue/editor'
//@ts-ignore:disable-next-line
import JsonSchemaEditor from 'json-schema-editor-vue3'
import 'json-schema-editor-vue3/lib/json-schema-editor-vue3.css'
import FormBuilder from '../../../entidades/builder/form/infrastructure/FormBuilder.vue';
//import DynamicComponentBuilder from '../../../entidades/builder/form/infrastructure/DynamicComponentBuilder.vue';
//import DynamicHtmlComponentBuilder  from '../../../entidades/builder/form/infrastructure/DynamicHtmlComponentBuilder.vue';
import LookUpEditor from '../../../entidades/builder/crud/infrastructure/search/LookUpEditor.vue';
//@ts-ignore:disable-next-line
import {Splitpanes, Pane}  from 'splitpanes';
import 'splitpanes/dist/splitpanes.css'
import Dock from 'primevue/dock';
import { JsonTreeView } from "json-tree-view-vue3";

// import CodeHighlight from '../AppCodeHighlight'
// import BlockViewer from '../componentes/base/common/primetemplate/BlockViewer.vue'

import VueCookies, { globalCookiesConfig } from 'vue3-cookies'

import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'prismjs/themes/prism-coy.css'
// import HelperException from './HelperException'
// @ts-ignore:disable-next-line
import VueCryptojs from 'vue-cryptojs'

import { CustomMenu as MenuApp } from '../../domain/modelos/menu/CustomMenu'
// import { Router } from "vue-router";
import store from '../almacen/index'
import { Actions } from '../almacen/enums/StoreEnums'
import { createI18n } from 'vue-i18n'
import { FilterService } from 'primevue/api';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon'



/**
 * @description MainGrapper function
 */

export let app = createApp({})

class MainWrapper {
  /**
   * @description property to share vue instance
   */

  static async init (instance: App<Element>, menusApp: MenuApp[], domain = 'ilinium.intrasoft.es') {
    if (instance) {
      app = instance
    }

    app.config.globalProperties.$appState = reactive({ fullWidth: false })
    app.config.globalProperties.publicPath = process.env.BASE_URL

    app.provide('publicPath', app.config.globalProperties.publicPath)

    app.use(VueCookies)
    
    // eslint-disable-next-line
    //dotnet.boot();

    globalCookiesConfig({
      expireTimes: '30d',
      path: '/',
      domain,
      secure: false,
      sameSite: 'None'
    })

    const i18n = createI18n({
      legacy: false, // you must set `false`, to use Composition API
      locale: 'es', // set locale
      fallbackLocale: 'es', // set fallback locale    
      // Refer a global scope Composer instance of i18n
	    globalInjection: true,        
      
      // something vue-i18n options here ...
    })

    app.use(i18n);

    // app.use(a);
    // var a = Actions.ADD_BODY_ATTRIBUTE;



    app.use(PrimeVue, {
      locale: {
        IsNull:'Es Nulo',
        IsNotNull:'No es Nulo',
        CurrentMonth:'Mes actual',
        PreviousMonth:'Mes anterior',
        CurrentYear:'Año actual',
        PreviousYear:'Año anterior',
        CurrentWeek:'Semana actual',
        PreviousWeek:'Semana anterior',
        startsWith: 'Comienza con',
        contains: 'Contiene',
        notContains: 'No contiene',
        endsWith: 'Termina con',
        equals: 'Igual',
        notEquals: 'No es igual',
        noFilter: 'Sin filtro',
        lt: 'Menos que',
        lte: 'Menos que o igual a',
        gt: 'Mayor que',
        gte: 'Mayor qué o igual a',
        dateIs: 'La fecha es',
        dateIsNot: 'La fecha no es',
        dateBefore: 'La fecha es anterior',
        dateAfter: 'La fecha es posterior',
        clear: 'Borrar',
        apply: 'Aplicar',
        matchAll: 'Coincidir con todo',
        matchAny: 'Coincidir con cualquiera',
        addRule: 'Agregar regla',
        removeRule: 'Eliminar regla',
        accept: 'Sí',
        reject: 'No',
        choose: 'Seleccionar',
        upload: 'Subir ficheros',
        cancel: 'Cancelar',
        dayNames: [
          'Domingo',
          'Lunes',
          'Martes',
          'Miércoles',
          'Jueves',
          'Viernes',
          'Sábado'
        ],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
        monthNames: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre'
        ],
        monthNamesShort: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Agto',
          'Sep',
          'Oct',
          'Nov',
          'Dic'
        ],
        today: 'Hoy',
        weekHeader: 'Sem',
        firstDayOfWeek: 1,
        dateFormat: 'dd/mm/yy',
        weak: 'Débil',
        medium: 'Media',
        strong: 'Fuerte',
        passwordPrompt: 'Introduzca una contraseña',
        emptyFilterMessage: 'No se han encontrado resultados',
        emptyMessage: 'No hay opciones disponibles',
        aria: {
          star: '1 star',
          stars: '{star} stars',
          selectAll: 'All items selected',
          unselectAll: 'All items unselected',
          close: 'Close',
          previous: 'Previous',
          next: 'Next',
          navigation: 'Navigation',
          scrollTop: 'Scroll Top',
          moveTop: 'Move Top',
          moveUp: 'Move Up',
          moveDown: 'Move Down',
          moveBottom: 'Move Bottom',
          moveToTarget: 'Move to Target',
          moveToSource: 'Move to Source',
          moveAllToTarget: 'Move All to Target',
          moveAllToSource: 'Move All to Source',
          pageLabel: 'Page {page}',
          firstPageLabel: 'First Page',
          lastPageLabel: 'Last Page',
          nextPageLabel: 'Next Page',
          prevPageLabel: 'Previous Page',
          rowsPerPageLabel: 'Rows per page',
          jumpToPageDropdownLabel: 'Jump to Page Dropdown',
          jumpToPageInputLabel: 'Jump to Page Input',
          selectRow: 'Row Selected',
          unselectRow: 'Row Unselected',
          expandRow: 'Row Expanded',
          collapseRow: 'Row Collapsed',
          showFilterMenu: 'Show Filter Menu',
          hideFilterMenu: 'Hide Filter Menu',
          filterOperator: 'Filter Operator',
          filterConstraint: 'Filter Constraint',
          editRow: 'Row Edit',
          saveEdit: 'Save Edit',
          cancelEdit: 'Cancel Edit',
          listView: 'List View',
          gridView: 'Grid View',
          slide: 'Slide',
          slideNumber: '{slideNumber}',
          zoomImage: 'Zoom Image',
          zoomIn: 'Zoom In',
          zoomOut: 'Zoom Out',
          rotateRight: 'Rotate Right',
          rotateLeft: 'Rotate Left',

        }
      },
      ripple: true,

      filterMatchModeOptions:
      {
        text:["startsWith","contains","notContains","endsWith","equals","notEquals",'IsNull','IsNotNull'],
        numeric:["equals","notEquals","lt","lte","gt","gte",'IsNull','IsNotNull'],
        date:["dateIs","dateIsNot","dateBefore","dateAfter",'IsNull','IsNotNull','CurrentMonth','PreviousMonth','CurrentYear','PreviousYear','CurrentWeek','PreviousWeek']
    }
      
    })


    FilterService.register('IsNull', (value, filter) => {
      return (filter || !filter) && (value === undefined || value === null)
    });


    FilterService.register('IsNotNull', (value, filter) => {
      return (filter || !filter) && (value !== undefined || value !== null)
    });

    FilterService.register('CurrentMonth', (value, filter) => {
      const dates: { first: Date, last: Date }=HelperCommon.currentMonthDates();
      return (filter || !filter) && (value >=dates.first && value<=dates.last)
    });

    FilterService.register('PreviousMonth', (value, filter) => {
      const dates: { first: Date, last: Date }=HelperCommon.previousMonthDates();
      return (filter || !filter) && (value >=dates.first && value<=dates.last)
    });

    FilterService.register('CurrentYear', (value, filter) => {
      const dates: { first: Date, last: Date }=HelperCommon.currentYearDates();
      return (filter || !filter) && (value >=dates.first && value<=dates.last)
    });

    FilterService.register('PreviousYear', (value, filter) => {
      const dates: { first: Date, last: Date }=HelperCommon.previousYearDates();
      return (filter || !filter) && (value >=dates.first && value<=dates.last)
    });

    FilterService.register('CurrentWeek', (value, filter) => {
      const dates: { first: Date, last: Date }=HelperCommon.currentWeekDates();
      return (filter || !filter) && (value >=dates.first && value<=dates.last)
    });

    FilterService.register('PreviousWeek', (value, filter) => {
      const dates: { first: Date, last: Date }=HelperCommon.previousWeekDates();
      return (filter || !filter) && (value >=dates.first && value<=dates.last)
    });




    app.use(ConfirmationService)
    app.use(ToastService)
    // app.use(router);
    app.use(store)
    app.use(JsonSchemaEditor);

    app.directive('tooltip', Tooltip)
    app.directive('ripple', Ripple)
    // app.directive('code', CodeHighlight)
    app.directive('badge', BadgeDirective)
    app.directive('styleclass', StyleClass)

    app.component('Accordion', Accordion)
    app.component('AccordionTab', AccordionTab)
    app.component('AutoComplete', AutoComplete)
    app.component('Avatar', Avatar)
    app.component('AvatarGroup', AvatarGroup)
    app.component('Badge', Badge)
    app.component('Breadcrumb', Breadcrumb)
    app.component('Button', Button)
    app.component('Calendar', Calendar)
    app.component('Card', Card)
    app.component('Carousel', Carousel)
    app.component('Chart', Chart)
    app.component('Checkbox', Checkbox)
    app.component('Chip', Chip)
    app.component('Chips', Chips)
    app.component('ColorPicker', ColorPicker)
    app.component('Column', Column)
    app.component('ConfirmDialog', ConfirmDialog)
    app.component('ConfirmPopup', ConfirmPopup)
    app.component('ContextMenu', ContextMenu)
    app.component('DataTable', DataTable)
    app.component('DataView', DataView)
    app.component('DataViewLayoutOptions', DataViewLayoutOptions)
    app.component('Dialog', Dialog)
    app.component('Divider', Divider)
    app.component('Dropdown', Dropdown)
    app.component('Fieldset', Fieldset)
    app.component('FileUpload', FileUpload)
    app.component('CascadeSelect',CascadeSelect)
    //app.component('FullCalendar', FullCalendar)
    app.component('Galleria', Galleria)
    app.component('Image', Image)
    app.component('InlineMessage', InlineMessage)
    app.component('Inplace', Inplace)
    app.component('InputMask', InputMask)
    app.component('InputNumber', InputNumber)
    app.component('InputSwitch', InputSwitch)
    app.component('InputText', InputText)
    app.component('Knob', Knob)
    app.component('Listbox', Listbox)
    app.component('MegaMenu', MegaMenu)
    app.component('Menu', Menu)
    app.component('Menubar', Menubar)
    app.component('Message', Message)
    app.component('MultiSelect', MultiSelect)
    app.component('OrderList', OrderList)
    app.component('OrganizationChart', OrganizationChart)
    app.component('OverlayPanel', OverlayPanel)
    app.component('Paginator', Paginator)
    app.component('Panel', Panel)
    app.component('PanelMenu', PanelMenu)
    app.component('Password', Password)
    app.component('PickList', PickList)
    app.component('ProgressBar', ProgressBar)
    app.component('RadioButton', RadioButton)
    app.component('Rating', Rating)
    app.component('SelectButton', SelectButton)
    app.component('ScrollPanel', ScrollPanel)
    app.component('ScrollTop', ScrollTop)
    app.component('Slider', Slider)
    app.component('Sidebar', Sidebar)
    app.component('Skeleton', Skeleton)
    app.component('SpeedDial', SpeedDial)
    app.component('SplitButton', SplitButton)
    app.component('Splitter', Splitter)
    app.component('SplitterPanel', SplitterPanel)
    app.component('Steps', Steps)
    app.component('TabMenu', TabMenu)
    app.component('TabView', TabView)
    app.component('TabPanel', TabPanel)
    app.component('Tag', Tag)
    app.component('Textarea', Textarea)
    app.component('TieredMenu', TieredMenu)
    app.component('Timeline', Timeline)
    app.component('Toast', Toast)
    app.component('Toolbar', Toolbar)
    app.component('ToggleButton', ToggleButton)
    app.component('Tree', Tree)
    app.component('TreeSelect', TreeSelect)
    app.component('TreeTable', TreeTable)
    app.component('TriStateCheckBox', TriStateCheckBox)
    app.component('ProgressSpinner', ProgressSpinner)
    app.component('Dock',Dock);

    // split panel https://antoniandre.github.io/splitpanes/
    app.component('Splitpanes', Splitpanes)
    app.component('Pane', Pane)

    app.component('JsonTreeView',JsonTreeView)
    //app.component('Editor', Editor), app.component('BlockViewer', BlockViewer)

    // se añade el formbuilder y el dynamic
    app.component('FormBuilder',FormBuilder);
    //app.component('DynamicComponentBuilder',DynamicComponentBuilder);
    //app.component('DynamicHtmlComponentBuilder',DynamicHtmlComponentBuilder);    
    app.component('LookUpEditor',LookUpEditor);

    app.config.errorHandler = (err, vm, info) => {
      // err: error trace
      // vm: component in which error occured
      // info: Vue specific error information such as lifecycle hooks, events etc.

      // TODO: Perform any custom logic or log to server
      //HelperException.logException(err, vm, info)
      console.error(err); // Imprime el error en la consola del navegador
    }

    window.onerror = function (message, source, lineno, colno, error) {
      // TODO: write any custom logic or logs the error
      //HelperException.logExceptionW(message, source, lineno, colno, error)
    }

    app.use(VueCryptojs)

    app.mount('#app')

    // para arreglar el jsonstringfy que las fechas les hace un toisostring no les ponga la diferencia horaria
    Date.prototype.toJSON = function(){    
      if (this != null){
        const arrDate = [this.getFullYear().toString(),(this.getMonth() +1).toString().padStart(2, "0"),this.getDate().toString().padStart(2, "0") ]
        const arrTime = [this.getHours().toString().padStart(2, "0"),this.getMinutes().toString().padStart(2, "0"),this.getSeconds().toString().padStart(2, "0") ]

        return arrDate.join('-') + 'T' + arrTime.join(':') + '.'+ this.getMilliseconds().toString().padStart(3, "0") + 'Z'; // this['toGMTString']().match(/(\d{2}:\d{2}:\d{2})/)[0] + ':000Z';
  
      }
      return '';
  };

    if (menusApp.length > 0)
    {
      store.dispatch(Actions.SET_MENU, menusApp)
    }
    
  
  
  
    // inicializa el sharp
    // Binding 'Program.GetFrontendName' endpoint invoked in C#.
    //Program.getFrontendName = () => "Builder";

  // Subscribing to 'Program.OnMainInvoked' C# event.
      //Program.onMainInvoked.subscribe(console.log);

    // Initializing dotnet runtime and invoking entry point.
    //@ts-ignore:disable-next-line
    //await bootsharp.boot();
    
    //app.provide("Program",Program);
  
  }
}

export default MainWrapper

