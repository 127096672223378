<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
     <div :key="componentKey">
    
    
    <span class="p-float-label wrap-input100 validate-input">
        <Dropdown v-if="options.length > 1" v-model="selectedOptionId"  style="width: 250px;margin-right:200px" @change="onChangeOption()"
                        :options="options" filter showClear optionValue="id" optionLabel="description"
                        placeholder="" />
    </span>
    <span v-if="selectedOptionId != '' && selectedOptionId != '2'" class="wrap-input100 validate-input">
        <FloatLabel>
            <InputText type="text" placeholder="Email" v-model="passwordObject.userEmail" style="width: 250px; margin-top:10px"/>
        </FloatLabel>
    </span>
    <span v-if="selectedOptionId != '' && selectedOptionId != '2'">
        <small v-if="(v$.userEmail.$invalid && submitted) || v$.userEmail.$pending" class="p-error">{{
            v$.userEmail.required.$message }}</small>
    </span>
    
    <span v-if="selectedOptionId == '2'">
        <FloatLabel>
            <Password v-model="passwordObject.password" :feedback="false" 
                class="input100" name="passwordInput" style="width: 250px; margin-top:10px"
                placeholder="Contraseña" v-on:keyup.enter="onSubmitResetPassword"
                :class="{ 'p-password': true, 'p-invalid': v$.password.$invalid && submitted }"/>
        </FloatLabel>
    </span>
    <span v-if="selectedOptionId == '2'">
        <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending" class="p-error">{{
            v$.password.required.$message }}</small>
    </span>
    
    <span v-if="selectedOptionId == '2' || selectedOptionId == '3'" class="wrap-input100 validate-input">
        <div>
            <FloatLabel >
                <Password v-model="passwordObject.newpassword" :feedback="false" 
                    class="input100" name="passwordInput" style="width: 250px; margin-top:10px"
                    placeholder="Nueva contraseña" v-on:keyup.enter="onSubmitResetPassword"
                    :class="{ 'p-password': true, 'p-invalid': v$.newpassword.$invalid && submitted }"/>
            </FloatLabel>
        </div>
    </span>
    <span v-if="selectedOptionId == '2' || selectedOptionId == '3'">
        <small v-if="(v$.newpassword.$invalid && submitted) || v$.newpassword.$pending" class="p-error">{{
            v$.newpassword.required.$message }}</small>
    </span>

    <span v-if="selectedOptionId == '3'" class="wrap-input100 validate-input">
        <FloatLabel>
            <Password v-model="passwordObject.confirmnewpassword" :feedback="false" 
                class="input100" name="passwordInput" style="width: 250px; margin-top:10px"
                placeholder="Confirmar nueva contraseña" v-on:keyup.enter="onSubmitResetPassword"
                :class="{ 'p-password': true, 'p-invalid': v$.confirmnewpassword.$invalid && submitted }"/>
        </FloatLabel>
    </span>
    <span v-if="selectedOptionId == '3'">
        <small v-if="(v$.confirmnewpassword.$invalid && submitted) || v$.confirmnewpassword.$pending" class="p-error">{{
            v$.confirmnewpassword.required.$message }}</small>
    </span>

    <span v-if="selectedOptionId != ''" class="p-float-label wrap-input100 validate-input">
        <Button  id="reestablecerpwd" label="Restablecer contraseña" @click="onSubmitResetPassword" icon="pi pi-key"
                 style="width: 250px;margin-top:10px" class="p-button p-component p-button-secondary mr-2 mb-2" />
    </span>
</div>       
</template>
<script lang="ts">


import { defineComponent, reactive, onMounted, ref, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import Password from 'primevue/password';
import CustomValidate from '../../shared/CustomValidate.vue';
import SetPasswordTypeConst from '../../../../domain/Constants/SetPasswordTypeConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import MessageService, { MessageType } from '../../../../../../../common/infrastructure/servicios/MessageService';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import { Actions } from '../../../../../../../common/infrastructure/almacen/enums/StoreEnums';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import Environment from '../../../../../../../common/infrastructure/funciones/environment';

export default defineComponent({
    name: 'dynamicsetpassword',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
      
        slotProps: {
            type: Object,
            default: () => ({})
        },       
    },
    setup(props, context) {
        
        const store = useStore();
        const password = ref('')
        const options = reactive([]) as any;
        const selectedOptionId = ref('')
        const userEmail = ref('')

        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, resolveExpressions, baseOnBeforeUnmount
            ,fieldKey,fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

        const reglas = {
            password: {
                required: helpers.withMessage("La contraseña anterior es obligatoria", requiredIf(function() {
                    return selectedOptionId.value == '2';
                }))
            },
            newpassword: {
                required: helpers.withMessage("La nueva contraseña es obligatoria", requiredIf(function() {
                    return selectedOptionId.value == '2' || selectedOptionId.value == '3';
                }))
            },
            confirmnewpassword: {
                required: helpers.withMessage("Confirmar la contraseña es obligatorio", requiredIf(function() {
                    return selectedOptionId.value == '3';
                }))
            },
            userEmail: {
                required: helpers.withMessage("El email es obligatorio", requiredIf(function() {
                    return selectedOptionId.value != '' && selectedOptionId.value != '2';
                }))
            }
        }
        const submitted = ref(false);
        
        let isLoading = ref<boolean>(false);

        const passwordObject = ref({
            password: '',
            newpassword: '',
            confirmnewpassword: '',
            userEmail: '',
        });

        const v$ = useVuelidate(reglas, passwordObject as any);

        const isValidData = (): boolean => {
            submitted.value = true;
            v$.value.$reset();
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }

        function validateData(): boolean {
            
            if (selectedOptionId.value == '3' && passwordObject.value.confirmnewpassword != passwordObject.value.newpassword) {
                MessageService.showMessage(MessageType.Error, 'Error de validación', 'Las contraseñas no coinciden.');
                return false;
            }
            if ((selectedOptionId.value == '3') && !HelperCommon.validarEmail(passwordObject.value.userEmail)) {
                MessageService.showMessage(MessageType.Error, 'Error de validación', 'El email no es válido.');
                return false;
            }
            return true;
        }

        const onSubmitResetPassword = () => {
            
            if (isValidData() && validateData()) {
                HelperLoading.showLoading();
                if (selectedOptionId.value == '1') {
                    store.dispatch(Actions.FORGOT_PASSWORD, [passwordObject.value.userEmail, Environment.APPID, Environment.APPVERSION, props.container]).then(() => {
                        MessageService.showToast(MessageType.Correcto, '', 'Se ha enviado el correo de reestablecimiento de contraseña correctamente.');
                    }).finally(() => {
                        HelperLoading.hideLoading();
                    });
                }
                else {
                    store.dispatch(Actions.RESET_PASSWORD_MANUAL, [passwordObject.value.userEmail, passwordObject.value.newpassword, passwordObject.value.password, selectedOptionId.value, Environment.APPID, Environment.APPVERSION, props.container]).then(() => {
                        MessageService.showToast(MessageType.Correcto, '', 'Ha sido modificada la contraseña de la cuenta en nuestro sistema correctamente.');
                    }).finally(() => {
                        HelperLoading.hideLoading();
                    });
                }
            }
        };

        function onChangeOption() {
          
        };

        onMounted(() => {
            
            let allowResetPwd = getPropertyValue(SetPasswordTypeConst.ALLOWRESETPWD);
            let allowSetPwdAdm = getPropertyValue(SetPasswordTypeConst.ALLOWSETPWDADM);
            let allowSetPwdUsr = getPropertyValue(SetPasswordTypeConst.ALLOWSETPWDUSR);

            const allowedResetPwdGroupIds = allowResetPwd?.split(',');
            const allowedSetPwdAdmGroupIds = allowSetPwdAdm?.split(',');
            const allowedSetPwdUsrGroupIds = allowSetPwdUsr?.split(',');
            const isResetAllowed = allowedResetPwdGroupIds?.some(id => store.getters.getCurrentUser.groups.some(group => group.id.toString() === id));
            const isSetAdmAllowed = allowedSetPwdAdmGroupIds?.some(id => store.getters.getCurrentUser.groups.some(group => group.id.toString() === id));
            const isSetUsrAllowed = allowedSetPwdUsrGroupIds?.some(id => store.getters.getCurrentUser.groups.some(group => group.id.toString() === id));

            options.value = [];
            if (store.getters.getCurrentUser.isAdmin || isResetAllowed) {
                options.push({id:'1', description:'Enviar correo de restablecer contraseña'})
            }
            if (store.getters.getCurrentUser.isAdmin || isSetUsrAllowed) {
                options.push({id:'2', description:'Cambiar contraseña usuario logueado'})
            }
            if (store.getters.getCurrentUser.isAdmin || isSetAdmAllowed) {
                options.push({id:'3', description:'Cambiar contraseña como administrador'})
            }
            
            if (options.length == 1) {
                selectedOptionId.value = options[0].id;
            }
        })

        
        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        return {
            MessageService,
            MessageType,
            passwordObject,
            BaseControlTypeConst,
            v$,
            submitted,
            isValidData,
            ObjectGroupConst,
            onSubmitResetPassword,
            options,
            selectedOptionId,
            onChangeOption,
            userEmail,
            getPropertyBooleanValue,
            vmodel,
            componentKey
        };
    },
});
</script>
<style scoped></style>
