import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "flex flex-column md:flex-row gap-4" }
const _hoisted_3 = { class: "w-full md:w-3 xl:w-2 xl:p-3" }
const _hoisted_4 = { class: "md:w-9 xl:w-10 xl:p-3" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "p-0 m-0" }
const _hoisted_7 = { class: "surface-section grid grid-nogutter formgrid flex-column md:flex-row gap-6 p-5 border-round" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "flex column-gap-3 justify-content-end p-5 border-top-1 surface-border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_MailSidebar = _resolveComponent("MailSidebar")!
  const _component_MailTypes = _resolveComponent("MailTypes")!
  const _component_MailDetail = _resolveComponent("MailDetail")!
  const _component_DynamicEmail = _resolveComponent("DynamicEmail")!
  const _component_Reply = _resolveComponent("Reply")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_MailSidebar, {
            items: _ctx.sidebarItems,
            activeMailItem: _ctx.activeMailItem,
            onNavigate: _ctx.handleNavigate
          }, null, 8 /* PROPS */, ["items", "activeMailItem", "onNavigate"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            ((_ctx.sections.inbox || _ctx.sections.draft || _ctx.sections.trash || _ctx.sections.archived || _ctx.sections.sent || _ctx.sections.spam || _ctx.sections.starred || _ctx.sections.important || _ctx.sections.folder != '' || _ctx.sections.group != '') && _ctx.filterFirstLoaded)
              ? (_openBlock(), _createBlock(_component_MailTypes, {
                  key: 0,
                  ref: "emailTypes",
                  mails: _ctx.allMails,
                  filterDefaultValue: _ctx.filterDefaultValue,
                  totalMails: _ctx.mailsCount,
                  onNavigate: _ctx.handleNavigate,
                  onChangeAccount: _ctx.toggleChangeAccount,
                  onReply: _ctx.showReplyDialog,
                  onChangeMailType: _ctx.onChangeMailType,
                  onRefresh: _ctx.refresh,
                  onPageChange: _ctx.getMailsBySection,
                  onTrash: _ctx.moveToTrash,
                  onArchive: _ctx.moveToArchive,
                  onSpam: _ctx.moveToSpam
                }, null, 8 /* PROPS */, ["mails", "filterDefaultValue", "totalMails", "onNavigate", "onChangeAccount", "onReply", "onChangeMailType", "onRefresh", "onPageChange", "onTrash", "onArchive", "onSpam"]))
              : _createCommentVNode("v-if", true),
            (_ctx.sections.detail)
              ? (_openBlock(), _createBlock(_component_MailDetail, {
                  key: 1,
                  mails: _ctx.allMails,
                  emailId: _ctx.emailId,
                  groupId: _ctx.sections.group,
                  onNavigate: _ctx.handleNavigate,
                  onReply: _ctx.showReplyDialog,
                  onChangeMailType: _ctx.onChangeMailType,
                  onRefresh: _ctx.refresh,
                  onTrash: _ctx.moveToTrash,
                  onArchive: _ctx.moveToArchive,
                  onSpam: _ctx.moveToSpam
                }, null, 8 /* PROPS */, ["mails", "emailId", "groupId", "onNavigate", "onReply", "onChangeMailType", "onRefresh", "onTrash", "onArchive", "onSpam"]))
              : _createCommentVNode("v-if", true),
            (_ctx.sections.compose)
              ? (_openBlock(), _createBlock(_component_DynamicEmail, {
                  key: 2,
                  paramEmail: _ctx.myDraft,
                  "onUpdate:paramEmail": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.myDraft) = $event)),
                  onUpdateEmailId: _ctx.updateEmailId,
                  objIdValue: _ctx.objIdValue,
                  idObjectValue: _ctx.idObjectValue,
                  container: _ctx.container,
                  Component: _ctx.Component,
                  "onUpdate:Component": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.Component) = $event)),
                  slotProps: _ctx.slotProps,
                  onNavigate: _ctx.handleNavigate,
                  onPageChange: _ctx.getDrafts
                }, {
                  to: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "to")
                  ]),
                  body: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "body")
                  ]),
                  _: 3 /* FORWARDED */
                }, 8 /* PROPS */, ["paramEmail", "onUpdateEmailId", "objIdValue", "idObjectValue", "container", "Component", "slotProps", "onNavigate", "onPageChange"]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ]),
    _createVNode(_component_Reply, {
      visible: _ctx.dialogVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      mailDetail: _ctx.mailDetail,
      onSave: _ctx.onSaveReplyMail,
      "onUpdate:dialogVisible": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChangeDialogVisibility()))
    }, null, 8 /* PROPS */, ["visible", "mailDetail", "onSave"]),
    _createVNode(_component_Dialog, {
      header: "Cambiar a otra cuenta",
      visible: _ctx.isDialogVisible,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isDialogVisible) = $event)),
      modal: "",
      class: "mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6",
      contentClass: "border-round-bottom border-top-1 surface-border p-0"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", {
                for: "value",
                class: _normalizeClass({ 'p-invalid': _ctx.v$.$error && _ctx.submitted, 'customrequired': true })
              }, "Correo: ", 2 /* CLASS */),
              _createVNode(_component_InputText, {
                id: "value",
                modelValue: _ctx.changeAccountEmail,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.changeAccountEmail) = $event)),
                type: "text",
                class: _normalizeClass({ 'p-invalid': _ctx.v$.$error && _ctx.submitted }),
                "aria-describedby": "text-error"
              }, null, 8 /* PROPS */, ["modelValue", "class"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Button, {
              type: "button",
              class: "h-3rem",
              icon: "pi pi-users",
              label: "Cambiar cuenta",
              onClick: _ctx.doChangeAccount
            }, null, 8 /* PROPS */, ["onClick"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ], 64 /* STABLE_FRAGMENT */))
}