import { defineComponent } from "vue";
import { IapComponent } from "../../../component/domain/iapComponent";
import ComponentRender from "./ComponentRender";
import { Container } from "inversify";


import DynamicEditor from '../../infrastructure/controls/editorTemplates/prime/DynamicEditor.vue';
import DynamicCrudTabla from '../../infrastructure/controls/editorTemplates/prime/DynamicCrudTabla.vue';
import DynamicEmail from '../../infrastructure/controls/editorTemplates/prime/DynamicEmail.vue';
import DynamicEmailDashboard from '../../infrastructure/controls/editorTemplates/prime/DynamicEmailDashboard.vue';
import DynamicBrowsing from '../../infrastructure/controls/editorTemplates/prime/DynamicBrowsing.vue';
import DynamicSignInWith from '../../infrastructure/controls/editorTemplates/prime/DynamicSignInWith.vue';
import DynamicLogout from '../../infrastructure/controls/editorTemplates/prime/DynamicLogout.vue';
import DynamicWhoAmIProfiles from '../../infrastructure/controls/editorTemplates/prime/DynamicWhoAmIProfiles.vue';
import DynamicLanguage from '../../infrastructure/controls/editorTemplates/prime/DynamicLanguage.vue';
import DynamicSetup from '../../infrastructure/controls/editorTemplates/prime/DynamicSetup.vue';
import DynamicLayout from '../../infrastructure/controls/editorTemplates/prime/DynamicLayout.vue';
import DynamicButton from '../../infrastructure/controls/editorTemplates/prime/DynamicButton.vue';
import DynamicToogleButton from '../../infrastructure/controls/editorTemplates/prime/DynamicToogleButton.vue';
import DynamicSplitButton from '../../infrastructure/controls/editorTemplates/prime/DynamicSplitButton.vue';
import DynamicPanel from '../../infrastructure/controls/editorTemplates/prime/DynamicPanel.vue';
import DynamicCheckBox from '../../infrastructure/controls/editorTemplates/prime/DynamicCheckBox.vue';
import DynamicTriStateCheckBox from '../../infrastructure/controls/editorTemplates/prime/DynamicTriStateCheckBox.vue';
import DynamicSelectButton from '../../infrastructure/controls/editorTemplates/prime/DynamicSelectButton.vue';
import DynamicTree from '../../infrastructure/controls/editorTemplates/prime/DynamicTree.vue'
import DynamicTreeSelect from '../../infrastructure/controls/editorTemplates/prime/DynamicTreeSelect.vue'
import DynamicTreeTable from '../../infrastructure/controls/editorTemplates/prime/DynamicTreeTable.vue'
import DynamicDropDown from '../../infrastructure/controls/editorTemplates/prime/DynamicDropDown.vue';
import DynamicDropDownCascade from '../../infrastructure/controls/editorTemplates/prime/DynamicDropDownCascade.vue';
import DynamicInputSwitch from '../../infrastructure/controls/editorTemplates/prime/DynamicInputSwitch.vue';
import DynamicInputText from '../../infrastructure/controls/editorTemplates/prime/DynamicInputText.vue';
import DynamicInputNumber from '../../infrastructure/controls/editorTemplates/prime/DynamicInputNumber.vue';
import DynamicLabel from '../../infrastructure/controls/editorTemplates/prime/DynamicLabel.vue';
import DynamicRadioButton from '../../infrastructure/controls/editorTemplates/prime/DynamicRadioButton.vue';
import DynamicMultiSelect from '../../infrastructure/controls/editorTemplates/prime/DynamicMultiSelect.vue';
import DynamicChip from '../../infrastructure/controls/editorTemplates/prime/DynamicChip.vue';
import DynamicDivider from '../../infrastructure/controls/editorTemplates/prime/DynamicDivider.vue';
import DynamicImage from '../../infrastructure/controls/editorTemplates/prime/DynamicImage.vue';
import DynamicHtmlA from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlA.vue';
import DynamicTabPanel from '../../infrastructure/controls/editorTemplates/prime/DynamicTabPanel.vue';
import DynamicTabView from '../../infrastructure/controls/editorTemplates/prime/DynamicTabView.vue';
import DynamicTextArea from '../../infrastructure/controls/editorTemplates/prime/DynamicTextArea.vue';
import DynamicRating from '../../infrastructure/controls/editorTemplates/prime/DynamicRating.vue';
import DynamicSetPassword from '../../infrastructure/controls/editorTemplates/prime/DynamicSetPassword.vue';
import DynamicPassword from '../../infrastructure/controls/editorTemplates/prime/DynamicPassword.vue';
import DynamicBadge from '../../infrastructure/controls/editorTemplates/prime/DynamicBadge.vue';
import DynamicKnob from '../../infrastructure/controls/editorTemplates/prime/DynamicKnob.vue';
import DynamicColorPicker from '../../infrastructure/controls/editorTemplates/prime/DynamicColorPicker.vue';
import DynamicHtmlSpan from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlSpan.vue';
import DynamicHtmlB from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlB.vue';
import DynamicHtmlBr from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlBr.vue';
import DynamicHtmlEm from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlEm.vue';
import DynamicHtmlH1 from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlH1.vue';
import DynamicHtmlH2 from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlH2.vue';
import DynamicHtmlH3 from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlH3.vue';
import DynamicHtmlH4 from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlH4.vue';
import DynamicHtmlH5 from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlH5.vue';
import DynamicHtmlH6 from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlH6.vue';
import DynamicHtmlHr from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlHr.vue';
import DynamicHtmlI from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlI.vue';
import DynamicHtmlP from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlP.vue';
import DynamicHtmlSmall from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlSmall.vue';
import DynamicHtmlStrong from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlStrong.vue';
import DynamicHtmlDiv from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlDiv.vue';
import DynamicHtmlUl from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlUl.vue';
import DynamicHtmlLi from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlLi.vue';
import DynamicCard from '../../infrastructure/controls/editorTemplates/prime/DynamicCard.vue';
import DynamicTimeLine from '../../infrastructure/controls/editorTemplates/prime/DynamicTimeLine.vue';
import DynamicAvatar from '../../infrastructure/controls/editorTemplates/prime/DynamicAvatar.vue';
import DynamicToolBar from '../../infrastructure/controls/editorTemplates/prime/DynamicToolBar.vue';
import DynamicTag from '../../infrastructure/controls/editorTemplates/prime/DynamicTag.vue';
import DynamicHtmlInput from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlInput.vue';
import DynamicColumn from '../../infrastructure/controls/editorTemplates/prime/DynamicColumn.vue';
import DynamicCalendar from '../../infrastructure/controls/editorTemplates/prime/DynamicCalendar.vue';
import DynamicScrollPanel from '../../infrastructure/controls/editorTemplates/prime/DynamicScrollPanel.vue';
import DynamicDataTable from '../../infrastructure/controls/editorTemplates/prime/DynamicDataTable.vue';
import DynamicHtmlIframe from '../../infrastructure/controls/editorTemplates/prime/DynamicHtmlIframe.vue';
import DynamicDataView from '../../infrastructure/controls/editorTemplates/prime/DynamicDataView.vue';
import DynamicMenu from '../../infrastructure/controls/editorTemplates/prime/DynamicMenu.vue';
import DynamicMenuItem from '../../infrastructure/controls/editorTemplates/prime/DynamicMenuItem.vue';
import DynamicFieldset from '../../infrastructure/controls/editorTemplates/prime/DynamicFieldset.vue';
import DynamicDialog from '../../infrastructure/controls/editorTemplates/prime/DynamicDialog.vue';
import DynamicLookUp from '../../infrastructure/controls/editorTemplates/prime/DynamicLookUp.vue';
import DynamicAutocomplete from '../../infrastructure/controls/editorTemplates/prime/DynamicAutocomplete.vue';
import DynamicChartBar from '../../infrastructure/controls/editorTemplates/prime/DynamicChartBar.vue';
import DynamicChartCirc from '../../infrastructure/controls/editorTemplates/prime/DynamicChartCirc.vue';
import DynamicCrudDocument from '../../infrastructure/controls/editorTemplates/prime/DynamicCrudDocument.vue';
import DynamicFullCalendar from '../../infrastructure/controls/editorTemplates/prime/DynamicFullCalendar.vue';
import DynamicInputMask from '../../infrastructure/controls/editorTemplates/prime/DynamicInputMask.vue';
import DynamicStructuredField from '../../infrastructure/controls/editorTemplates/prime/DynamicStructuredField.vue';
import DynamicExpression from '../../infrastructure/controls/editorTemplates/prime/DynamicExpression.vue';
import { IapComponentAttribute } from "../../../component/domain/iapComponentAttribute";
import { useStore } from 'vuex';
import ComponentDataForm from "../../../designer/domain/ComponentDataForm";



import ControlTypeConst from '../Constants/ControlTypeConst';
import CatalogCompConst from '../../../catalog/domain/const/CatalogCompConst';
import PanelTypeConst from '../Constants/PanelTypeConst';
import DataViewTypeConst from '../Constants/DataViewTypeConst';
import TimeLineTypeConst from '../Constants/TimeLineTypeConst';
import CardTypeConst from '../Constants/CardTypeConst';
import CrudTableTypeConst from '../Constants/CrudTableTypeConst';
import MenuTypeConst from '../Constants/MenuTypeConst';
import TabPanelTypeConst from '../Constants/TabPanelTypeConst';
import FieldsetTypeConst from '../Constants/FieldsetTypeConst';
import TabViewTypeConst from '../Constants/TabViewTypeConst';
import BaseControlTypeConst from '../Constants/BaseControlTypeConst';
import LookUpEditorTypeConst from '../Constants/LookUpEditorTypeConst';
import AutocompleteTypeConst from '../Constants/AutocompleteTypeConst';
import DialogTypeConst from '../Constants/DialogTypeConst';
import CatalogModelValueConst from '../../../catalog/domain/const/CatalogModelValueConst';
import StructuredFieldControlTypeConst from '../Constants/StructuredFieldControlTypeConst';



import ComponentHelperRender from './ComponentHelperRender';





const BuildComponent = (componentId: number, comps: IapComponent[]) => {

    let html = '';
    const store = useStore();

    const { printDynamicCode } = ComponentRender(store);
    html =  printDynamicCode(componentId, comps, html,['v-model',':']);    
    const comp = defineComponent({
        template: `${html}`,
        components: {
            DynamicCalendar,
            DynamicCrudTabla,
            DynamicEmail,
            DynamicEmailDashboard,
            DynamicSetPassword,
            DynamicBrowsing,
            DynamicSignInWith,
            DynamicLogout,
            DynamicWhoAmIProfiles,
            DynamicLanguage,
            DynamicSetup,
            DynamicLayout,
            DynamicButton,
            DynamicPanel,
            DynamicCheckBox,
            DynamicTriStateCheckBox,
            DynamicSelectButton,
            DynamicTree,
            DynamicTreeSelect,
            DynamicTreeTable,
            DynamicDropDown,
            DynamicDropDownCascade,
            DynamicInputSwitch,
            DynamicInputText,
            DynamicInputNumber,
            DynamicLabel,
            DynamicRadioButton,
            DynamicMultiSelect,
            DynamicChip,
            DynamicDivider,
            DynamicImage,
            DynamicHtmlA,
            DynamicTabPanel,
            DynamicTabView,
            DynamicTextArea,
            DynamicHtmlSpan,
            DynamicHtmlB,
            DynamicHtmlBr,
            DynamicHtmlEm,
            DynamicHtmlH1,
            DynamicHtmlH2,
            DynamicHtmlH3,
            DynamicHtmlH4,
            DynamicHtmlH5,
            DynamicHtmlH6,
            DynamicHtmlHr,
            DynamicHtmlI,
            DynamicHtmlP,
            DynamicHtmlSmall,
            DynamicHtmlStrong,
            DynamicHtmlDiv,
            DynamicHtmlUl,
            DynamicHtmlLi,
            DynamicCard,
            DynamicTimeLine,
            DynamicAvatar,
            DynamicToolBar,
            DynamicTag,
            DynamicScrollPanel,
            DynamicDataTable,
            DynamicColumn,
            DynamicHtmlInput,
            DynamicHtmlIframe,
            DynamicDataView,
            DynamicMenu,
            DynamicMenuItem,
            DynamicRating,
            DynamicFieldset,
            DynamicLookUp,
            DynamicPassword,
            DynamicBadge,
            DynamicKnob,
            DynamicColorPicker,
            DynamicToogleButton,
            DynamicEditor,
            DynamicSplitButton,
            DynamicDialog,
            DynamicAutocomplete,
            DynamicChartBar,
            DynamicChartCirc,
            DynamicCrudDocument,
            DynamicFullCalendar,
            DynamicInputMask,
            DynamicStructuredField,
            DynamicExpression
        },
        props:
        {
            container: {
                type: Object as () => Container
            },
            comps: {
                type: Object as () => ComponentDataForm[],
                default: () => ([])
            },
            rootParentId:
            {
                type: Number,
                default: (-1)
            },

            slotProps: {
                type: Object,
                default: () => ({})
            },

            formKey: {
                type: String,
                default: ''
            },
            lookUpComps: {
                type: Object as () => {
                    compId: number;
                    iapComponentAttribute: IapComponentAttribute | null;
                }[],
                default: () => ({})
            },
        },
        setup(props) {

            const store = useStore();

            const { getPropertyValue,getPropertyBooleanValue,getPropertyNumberValue, getLayoutComponent, getContentComponent, isLayoutComponent, getAllLayoutComponent,isVisibleTabPanel,compIdIndexTab } = ComponentHelperRender({} as any ,props.slotProps,store);

            return {
                //comps
                ControlTypeConst
                ,CatalogCompConst
                ,PanelTypeConst
                ,DataViewTypeConst
                ,TimeLineTypeConst
                , CardTypeConst
                , CrudTableTypeConst
                ,MenuTypeConst
                , TabPanelTypeConst
                , FieldsetTypeConst
                , TabViewTypeConst
                , BaseControlTypeConst
                , LookUpEditorTypeConst
                , AutocompleteTypeConst
                , DialogTypeConst
                , CatalogModelValueConst
                ,StructuredFieldControlTypeConst
                ,getLayoutComponent
                ,getContentComponent
                ,isLayoutComponent
                ,getAllLayoutComponent
                ,isVisibleTabPanel
                ,getPropertyValue
                ,getPropertyBooleanValue
                ,getPropertyNumberValue
                ,compIdIndexTab
            }
        }
        //return () => {}
    });
    html='';
    return comp;
}


export default { BuildComponent }