import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createSlots as _createSlots, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_LookUpEditor = _resolveComponent("LookUpEditor")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_NewObjectRegister = _resolveComponent("NewObjectRegister")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DocumentViewer = _resolveComponent("DocumentViewer")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getPropertyBooleanValue(_ctx.LookUpEditorTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.isVisible)
      ? _withDirectives((_openBlock(), _createBlock(_component_LookUpEditor, {
          container: _ctx.container,
          id: _ctx.getPropertyValue(_ctx.LookUpEditorTypeConst.ID),
          key: _ctx.componentKey,
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.LookUpEditorTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted }),
          name: _ctx.getPropertyValue(_ctx.LookUpEditorTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.LookUpEditorTypeConst.DISABLED),
          visible: _ctx.getPropertyBooleanValue(_ctx.LookUpEditorTypeConst.VISIBLE),
          placeholder: _ctx.getPropertyValue(_ctx.LookUpEditorTypeConst.PLACEHOLDER),
          filterConditions: _ctx.initialfilterConditions.concat(_ctx.filterConditions).concat(_ctx.additionalfilterConditions) ?? [],
          dataSourceLookUp: _ctx.dsLookUp,
          lookUpDesc: _ctx.description,
          dialogWidth: _ctx.getPropertyValue(_ctx.LookUpEditorTypeConst.DIALOGWIDTH),
          lkStyle: _ctx.getPropertyValue(_ctx.LookUpEditorTypeConst.STYLE),
          showSearch: _ctx.getPropertyBooleanValue(_ctx.LookUpEditorTypeConst.SHOWSEARCH),
          showClear: _ctx.getPropertyBooleanValue(_ctx.LookUpEditorTypeConst.SHOWCLEAR),
          catalogsData: _ctx.catalogTypes()?.flatMap(x => x.iapCatalogs),
          hasDetail: _ctx.hasDetail,
          hasViewCommand: _ctx.hasViewCommand,
          hasSaveCommand: _ctx.hasSaveCommand,
          isAddOnTheFly: _ctx.isAddOnTheFly,
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event)),
          "onChange:SelectedLookUpData": _ctx.selectedLookUpData,
          "onChange:Document": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click:refreshData'))),
          Component: _ctx.Component,
          rowData: _ctx.getRowData,
          "onView:Details": _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDetail())),
          "onView:NewRegister": _cache[3] || (_cache[3] = ($event: any) => (_ctx.openNewRegistration()))
        }, {
          dialoghead: _withCtx(() => [
            _renderSlot(_ctx.$slots, "dialoghead")
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["container", "id", "class", "name", "disabled", "visible", "placeholder", "filterConditions", "dataSourceLookUp", "lookUpDesc", "dialogWidth", "lkStyle", "showSearch", "showClear", "catalogsData", "hasDetail", "hasViewCommand", "hasSaveCommand", "isAddOnTheFly", "modelValue", "onChange:SelectedLookUpData", "Component", "rowData"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.LookUpEditorTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    (_ctx.isVisible)
      ? (_openBlock(), _createBlock(_component_CustomValidate, {
          key: 2,
          submitted: _ctx.submitted,
          "onUpdate:submitted": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.submitted) = $event)),
          vObject: _ctx.v$,
          "onUpdate:vObject": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$) = $event))
        }, null, 8 /* PROPS */, ["submitted", "vObject"]))
      : _createCommentVNode("v-if", true),
    (_ctx.hasDetail)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 3,
          ref: "opDetails",
          visible: _ctx.opViewDetails,
          "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.opViewDetails) = $event)),
          modal: "",
          header: " ",
          styleClass: "mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6",
          contentStyleClass: "border-round-bottom border-top-1 surface-border p-0",
          closable: true,
          maximizable: true
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "detail")
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["visible"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_Dialog, {
      ref: "opNewR",
      visible: _ctx.opNewRegister,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.opNewRegister) = $event)),
      modal: "",
      header: " ",
      styleClass: "mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6",
      contentStyleClass: "border-round-bottom border-top-1 surface-border p-0",
      closable: true,
      maximizable: true
    }, _createSlots({
      default: _withCtx(() => [
        (_ctx.isDynamicAddOnTheFly && !_ctx.hasSaveCommand)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_NewObjectRegister, {
                container: _ctx.container,
                dataSourceLookUp: _ctx.dsLookUp,
                idObjeto: _ctx.CatalogObjectTypeConst.LOOKUP,
                catalogsData: _ctx.catalogTypes()?.flatMap(x => x.iapCatalogs),
                objetoId: _ctx.dsLookUp?.id,
                onRefreshData: _ctx.doRefreshData
              }, null, 8 /* PROPS */, ["container", "dataSourceLookUp", "idObjeto", "catalogsData", "objetoId", "onRefreshData"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.layoutAddOntheFly && _ctx.layoutAddOntheFly.get(_ctx.layoutAddOntheFly?.id ,'children')?.length > 0  && !_ctx.hasSaveCommand)
                ? _renderSlot(_ctx.$slots, "addonthefly", { key: 0 })
                : (_openBlock(), _createBlock(_component_Message, {
                    key: 1,
                    severity: "warn",
                    closable: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("No tiene nada definido para el slot \"Add on the fly\"")
                    ]),
                    _: 1 /* STABLE */
                  }))
            ]))
      ]),
      _: 2 /* DYNAMIC */
    }, [
      (!_ctx.isDynamicAddOnTheFly && _ctx.layoutAddOntheFly && _ctx.layoutAddOntheFly.get(_ctx.layoutAddOntheFly?.id ,'children')?.length > 0  && !_ctx.hasSaveCommand)
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _createVNode(_component_Button, {
                id: "save",
                label: "Guardar",
                icon: "pi pi-save",
                class: "mr-2 p-button-rounded p-button-primary p-button-outlined",
                onClick: _ctx.saveCustomForm
              }, null, 8 /* PROPS */, ["onClick"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["visible"]),
    (_ctx.showViewerDocument && _ctx.selectedDoc)
      ? (_openBlock(), _createBlock(_component_DocumentViewer, {
          key: 4,
          visible: _ctx.showViewerDocument,
          "onUpdate:Visibility": _cache[8] || (_cache[8] = ($event: any) => {_ctx.showViewerDocument = false; _ctx.selectedDoc = null}),
          document: _ctx.selectedDoc,
          canvas: _ctx.canvas
        }, null, 8 /* PROPS */, ["visible", "document", "canvas"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}