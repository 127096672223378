import { PublicClientApplication, Configuration } from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-browser/dist";
import HelperApplication from "../../../../builder/application/infrastructure/functions/helperApplication";
import Environment from "../../../../../common/infrastructure/funciones/environment";
import CatalogConfigurationConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogConfigurationConst';
import store from "@ilinium/shared/src/common/infrastructure/almacen";


const appCfg = store.getters.getAppConfig
const { getAppKeyValue } = HelperApplication(undefined)


const msalConfig = ():Configuration => {  
  const msAppId:string = getAppKeyValue(CatalogConfigurationConst.MICROSOFT_APPID,Environment.ENVIRONMENT??'',appCfg)??'';
  const msTenantId:string = getAppKeyValue(CatalogConfigurationConst.MICROSOFT_TENANTID,Environment.ENVIRONMENT??'',appCfg)??'';
  const msUrlRedirect:string = getAppKeyValue(CatalogConfigurationConst.MICROSOFT_URL,Environment.ENVIRONMENT??'',appCfg)??'';
  
  return  {
    auth: {
      clientId: "" + msAppId, // ID de la aplicación registrada en Azure AD
      authority: "https://login.microsoftonline.com/" + msTenantId + "/v2.0", // URL de tu inquilino en Azure
      redirectUri: msUrlRedirect, // Redirigir a la URL de tu aplicación después del inicio de sesión
    },
    cache: {
      cacheLocation: "localStorage", // Puedes usar localStorage para persistir la sesión
      storeAuthStateInCookie: true, // Recomendado para IE11/Edge
    }
}};

export let msalInstance = new PublicClientApplication(msalConfig());

export async function initializeMsalInstance() {
  msalInstance = new PublicClientApplication(msalConfig())
  await msalInstance.initialize();
}

export async function authMicrosoft(): Promise<AccountInfo | null> {
  try {
    // Verificar si hay una interacción pendiente
    await msalInstance.handleRedirectPromise().catch(error => {
        console.error("Error en handleRedirectPromise", error);
    });

    const account = msalInstance.getAllAccounts()[0];
    let tokenResponse;

    if (account) {
        // Obtener el token de manera silenciosa si el usuario ya está autenticado 
        const silentTokenRequest = {
            scopes: ["user.read", "Mail.ReadWrite", "Mail.Send", "Mail.ReadWrite.Shared"],
            account: account,
        };
        try {
          tokenResponse = await msalInstance.acquireTokenSilent(silentTokenRequest);
        } catch (error) {
          // Mostrar el popup para autenticarse PORQUE CAMBIARON LOS PERMISOS
          tokenResponse = await msalInstance.loginPopup({
            scopes: ["user.read", "Mail.ReadWrite", "Mail.Send", "Mail.ReadWrite.Shared"],
          });
        }
    } else {
        // Mostrar el popup para autenticarse si no hay cuenta autenticada
        tokenResponse = await msalInstance.loginPopup({
            scopes: ["user.read", "Mail.ReadWrite", "Mail.Send", "Mail.ReadWrite.Shared"],
        });
    }

    return msalInstance.getAllAccounts()[0];
  } catch (error) {
      console.error("Error al iniciar sesión", error);
      return null;
  }
}

export async function getAccessToken(): Promise<string | null> {
  try {
    // Verificar si hay una interacción pendiente
    await msalInstance.handleRedirectPromise().catch(error => {
      console.error("Error en handleRedirectPromise", error);
    });

    const account = msalInstance.getAllAccounts()[0];
    let tokenResponse;

    if (account) {
      // Intentar obtener el token de manera silenciosa
      const silentTokenRequest = {
        scopes: ["user.read", "Mail.ReadWrite", "Mail.Send", "Mail.ReadWrite.Shared"], // Pedir el permiso correcto para leer los correos
        account: account,
      };

      tokenResponse = await msalInstance.acquireTokenSilent(silentTokenRequest);
    } else {
      // Mostrar el popup para autenticarse si no hay cuenta autenticada
      tokenResponse = await msalInstance.loginPopup({
        scopes: ["user.read", "Mail.ReadWrite", "Mail.Send", "Mail.ReadWrite.Shared"], // Pedir el permiso correcto para leer los correos
      });
    }

    // Devolver el accessToken en lugar del idToken
    return tokenResponse.accessToken;
  } catch (error) {
    console.error("Error al iniciar sesión", error);
    return null;
  }
}